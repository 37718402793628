import React from "react";

const NotFoundPage = () => {
  return (
    <div>
      De pagina kon niet worden gevonden. Probeer het later opnieuw.
    </div>
  );
};

export default NotFoundPage;
